import React from 'react'
import {
  Bar,
  Line,
  XAxis,
  Label,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts'
import { VStack, Text, HStack, useDisclosure, Stack } from '@chakra-ui/react'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import {
  ageColors,
  IndicatorColors,
  IndicatorLabels,
  IndicatorLabelsEn,
  IndicatorLabelsDe,
  keylessGroupBy,
} from '../utils'
import { useState } from 'react'
import { DashboardFilterType } from '../../DashboardFilterType'
import { useResultsPerSchoolAgeChart } from './useResultsPerSchoolAgeChart'
import { toNumber } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import ChartHelpButtonComponent from 'common/ChartDetailHelp/ChartHelpButtonComponent'
import ChartHelpComponent from 'common/ChartDetailHelp/ChartHelpComponent'

interface ResultsPerSchoolAgeChartProps {
  filter: DashboardFilterType
  helpDetailText: string | undefined
}

export const ResultsPerSchoolAgeChart = ({
  filter,
  helpDetailText,
}: ResultsPerSchoolAgeChartProps) => {
  const { stats, loading } = useResultsPerSchoolAgeChart(filter)
  // const {municipalityAverageData, error} = useMunicipalityByAreaAndAgeAverage(filter)



  const { t, i18n } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()
  const data = keylessGroupBy(stats, stat => stat.preschoolName).map(
    statCluster => {
      const ages: Record<number, string> = {}
      statCluster.forEach(stat => {
        const { age, average, participantAverage } = stat

        ages[`${age}_average`] = average?.toFixed(2)
        ages[`${age}_averageDifference`] = (
          participantAverage - average
        )?.toFixed(2)
      })

      const averagesSum = statCluster.reduce((acc, curr) => {
        return acc + (curr.average || 0)
      }, 0)
      const participantAveragesSum = statCluster.reduce((acc, curr) => {
        return acc + (curr.participantAverage || 0)
      }, 0)

      const average = (averagesSum / statCluster.length).toFixed(2)
      const participantAverage = (
        participantAveragesSum / statCluster.length
      ).toFixed(2)


      return {
        ...ages,
        average,
        participantAverage,
        preschoolName: statCluster[0].preschoolName,
      }
    },
  )

  let sum = 0;



  const municipalityAverage = (data.reduce((sum,item)=>sum+parseFloat(item.average),0)/data.length).toFixed(2)

  

  const municipalityParticipationAverage = (data.reduce((sum,item)=>sum+parseFloat(item.participantAverage),0)/data.length).toFixed(2)



  // Add municipalityAverage to each data entry
const updatedData = data.map(item => ({
  ...item,
  municipalityAverage: parseFloat(municipalityAverage), // Add municipalityAverage to the data object,
  municipalityParticipationAverage:parseFloat(municipalityParticipationAverage)
}));




  // const municipalityData = keylessGroupBy(municipalityAverageData, (stat:any) => stat.preschoolName).map(
  //   statCluster => {
  //     const ages: Record<number, string> = {}
  //     statCluster.forEach(stat => {
  //       const { age, average, participantAverage } = stat

  //       ages[`${age}_average`] = average?.toFixed(2)
  //       ages[`${age}_averageDifference`] = (
  //         participantAverage - average
  //       )?.toFixed(2)
  //     })

  //     const averagesSum = statCluster.reduce((acc, curr) => {
  //       return acc + (curr.average || 0)
  //     }, 0)
  //     const participantAveragesSum = statCluster.reduce((acc, curr) => {
  //       return acc + (curr.participantAverage || 0)
  //     }, 0)

  //     const average = (averagesSum / statCluster.length).toFixed(2)
  //     const participantAverage = (
  //       participantAveragesSum / statCluster.length
  //     ).toFixed(2)

  //     return {
  //       ...ages,
  //       average,
  //       participantAverage,
  //       preschoolName: statCluster[0].preschoolName,
  //     }
  //   },
  // )




  // const combinedData = data.map(item => {
  //   const municipalityItem = municipalityData.find(m => m.preschoolName === item.preschoolName);
  //   return {
  //     ...item,
  //     municipalityAverage: municipalityItem ? municipalityItem.average : undefined
  //   };
  // });




  const [tooltip, setTooltip] = useState<string>('')

  const CustomTooltip = ({ active, payload, label }: any) => {
    const { t } = useTranslation()
    if (active && payload && payload.length) {
      const barPayload = payload[0]?.payload
      if (stats.length === 1 && payload[0].payload.average === '0.00') {
        return (
          <VStack
            p='3'
            w='full'
            color='white'
            bg='#344352'
            borderRadius='md'
            justify='flex-start'
            alignItems='flex-start'
          >
            <Text fontWeight='semibold'>{t('Preschool_Total_Point')}</Text>
            <VStack w='full' justify='flex-start'>
              <HStack w='full'>
                <Text fontWeight='semibold'>
                  {t('ResultsPerAgeHorizonChart_participating')}{' '}
                </Text>
                <Text>{barPayload.average}</Text>
              </HStack>
              <HStack w='full'>
                <Text fontWeight='semibold'>
                  {t('ResultsPerAgeHorizonChart_Average')}{' '}
                </Text>
                <Text>{barPayload.participantAverage}</Text>
              </HStack>
            </VStack>
          </VStack>
        )
      } else {
        for (const bar of payload) {
          if (tooltip !== 'average') {
            if (bar?.dataKey.includes(tooltip)) {
              return (
                <VStack
                  p='3'
                  bg='#344352'
                  borderRadius='md'
                  alignItems='flex-start'
                  color='white'
                >
                  <Text fontWeight='semibold'>{label}</Text>
                  <VStack>
                    <HStack w='full'>
                      <Text fontWeight='semibold'>{t('Year')}: </Text>
                      <Text>{tooltip}</Text>
                    </HStack>

                    <HStack w='full'>
                      <Text fontWeight='semibold'>
                        {t('Average_Children_Complete')}{' '}
                      </Text>
                      <Text>{barPayload[`${tooltip}_average`]}</Text>
                    </HStack>

                    <HStack w='full'>
                      <Text fontWeight='semibold'>
                        {t('Average_Only_Children_Participating')}{' '}
                      </Text>
                      <Text>
                        {toNumber(barPayload[`${tooltip}_averageDifference`]) +
                          toNumber(barPayload[`${tooltip}_average`])}
                      </Text>
                    </HStack>
                  </VStack>
                </VStack>
              )
            }
          } 
          if(tooltip === 'municipalityAverage'){
return(
  <VStack
                p='3'
                w='full'
                color='white'
                bg='#344352'
                borderRadius='md'
                justify='flex-start'
                alignItems='flex-start'
              >
                <Text fontWeight='semibold'>
                 {t('Municipality_Average')}
                </Text>
                <VStack w='full' justify='flex-start'>
                  <HStack w='full'>
                    <Text fontWeight='semibold'>
                      {t('ResultsPerAgeHorizonChart_participating')}{' '}
                    </Text>
                    <Text>{barPayload.municipalityAverage}</Text>
                  </HStack>
                  <HStack w='full'>
                    <Text fontWeight='semibold'>
                      {t('Average_Only_Children_Participating')}{' '}
                    </Text>
                    <Text>{barPayload.municipalityParticipationAverage}</Text>
                  </HStack>
                </VStack>
              </VStack>
)
          }
          else {
            return (
              <VStack
                p='3'
                w='full'
                color='white'
                bg='#344352'
                borderRadius='md'
                justify='flex-start'
                alignItems='flex-start'
              >
                <Text fontWeight='semibold'>
                  {t('Preschool_Total_Point')}
                </Text>
                <VStack w='full' justify='flex-start'>
                  <HStack w='full'>
                    <Text fontWeight='semibold'>
                      {t('ResultsPerAgeHorizonChart_participating')}{' '}
                    </Text>
                    <Text>{barPayload.average}</Text>
                  </HStack>
                  <HStack w='full'>
                    <Text fontWeight='semibold'>
                      {t('Average_Only_Children_Participating')}{' '}
                    </Text>
                    <Text>{barPayload.participantAverage}</Text>
                  </HStack>
                </VStack>
              </VStack>
            )
          }
        }
      }
    }
    return null
  }

  // const ages = Array.from(new Set(stats.flatMap(stat => stat.age)))
  // const bars = ages.map(age => ({
  //   key: age,
  //   color: ageColors[age],
  // }))


  const ages = Array.from(new Set(stats.flatMap(stat => stat.age))).sort((a, b) => a - b)
  const bars = ages.map(age => ({
  key: age,
  color: ageColors[age],
  }))

  const helptext =
    i18n.language === 'se'
      ? 'Diagrammet visar ett genomsnittligt resultat för valt område och tidpunkt uppdelat per ålder. Med hjälp av filtret kan man välja och jämföra flera områden.'
      : i18n.language === 'de'
      ? 'Das Diagramm zeigt das durchschnittliche Ergebnis für das ausgewählte Gebiet und die Zeit, unterteilt nach Alter. Mithilfe des Filters können Sie mehrere Gebiete auswählen und vergleichen.'
      : 'The diagram shows the average result for the selected area and time divided by age. With the help of the filter, you can select and compare several areas.'

  return (
    <ChartContainer
      title={t('ResultsPerSchoolAgeChart_Results_by_area_and_age')}
      helptext={helptext}
      loading={loading}
    >
      {updatedData.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Text w={{ base: '100%', md: '80%' }} lineHeight={2}>
            {data.length > 0 ? helptext : null}
          </Text>
          <ChartHelpButtonComponent onToggle={onToggle} isOpen={isOpen} />
        </Stack>
      )}
      {helpDetailText && (
        <ChartHelpComponent helpDetailText={helpDetailText} isOpen={isOpen} />
      )}
      <br></br>
      {updatedData.length > 0 ? (
        <div style={{display:'flex', justifyContent: 'center'}}>     
        <ResponsiveContainer width='80%' height={400}>
          <ComposedChart
            data={updatedData}
            margin={{ bottom: 100 , top: 10, right: 50 }}
          >
            <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
            <YAxis ticks={[0, 50, 100]} unit='%' fontSize={12}>
              <Label
                dx={-25}
                angle={-90}
                fontSize={'12px'}
                value={t('Percent_Total_Points')}
                fontWeight='bold'
                style={{
                  textAnchor: 'middle',
                  width: '100%',
                }}
              />
            </YAxis>
            <XAxis
              dataKey='preschoolName'
              interval={0}
              angle={45}
              textAnchor='start'
              height={90}
              padding={{ right: 0 }}
              fontSize={12}
            >
              <Label
                value={t('ResultFilter_Preschool')}
                position='bottom'
                fontWeight='bold'
                offset={25}   
                fontSize={'12px'}            
              />
            </XAxis>
            <Tooltip
              formatter={value => `${value}%`}
              content={props => <CustomTooltip tooltip={tooltip} {...props} />}
              cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
            />
            <Legend
              iconSize={10}
              formatter={LegendItemRenderer}
              verticalAlign='middle'
              wrapperStyle={{ bottom: '10px', marginLeft: '40px' }}
            />
            {bars.map((bar, index) => (
              <>
                <Bar
                  barSize={12}
                  stackId={bar.key}
                  key={`${bar.key}_average_${index}`}
                  dataKey={`${bar.key}_average`}
                  fill={bar.color}
                  name={`${bar.key} ${t('years')}`}
                  isAnimationActive={true}
                  onMouseOver={() => {
                    setTooltip(`${bar.key}`)
                  }}
                />
                <Bar
                  barSize={12}
                  stackId={bar.key}
                  key={`${bar.key}_averageDifference_${index}`}
                  dataKey={`${bar.key}_averageDifference`}
                  fill={'#D3D3D3'}
                  legendType={'none'}
                  name={`${bar.key} ${t('years')}`}
                  isAnimationActive={true}
                  onMouseOver={() => {
                    setTooltip(`${bar.key}`)
                  }}
                />
              </>
            ))}
            <Line
              legendType='square'
              dataKey={t('child_non_participation')}
              stroke='#d4d4d4'
            />
           
             <Line
              type='monotone'
              name={
                i18n.language === 'se'
                ? IndicatorLabels.AveragePerAge
                : i18n.language === 'de'
                ? IndicatorLabelsDe.AveragePerAge
                : IndicatorLabelsEn.AveragePerAge
              }
              dataKey={'average'}
              stroke={IndicatorColors.ChildrenResult}
              strokeWidth={3}
              onMouseOver={() => {
                setTooltip('average')
              }}
            />


<Line
  type='monotone'
  dataKey={'municipalityAverage'} // This ensures a constant line at municipalityAverage
  name={t('Municipality_Average')}
  stroke='#D198C5FF'
  dot={false}
  strokeWidth={3}
  legendType='line'
  onMouseOver={() => {
    setTooltip('municipalityAverage')
  }}
/>

            
            
          </ComposedChart>
        </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-available'>
          {' '}
          {t('ResultsTable_No_Data')}
        </div>
      )}
    </ChartContainer>
  )
}
