import React, { useState } from 'react'
import {
  Bar,
  Label,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { ceil, floor } from 'lodash-es'
import { Stack, useDisclosure } from '@chakra-ui/react'
import { VStack, Text, HStack, StackProps } from '@chakra-ui/react'
import { useTaskService } from 'services/taskService'
import { useResultsPerTaskChart } from './useResultsPerAgeChart'
import { DashboardFilterType } from '../../DashboardFilterType'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import { BarChartColors, IndicatorColors } from 'data-models/preschool'
import {
  generateChartBarName,
  IndicatorLabels,
  IndicatorLabelsEn,
  IndicatorLabelsDe,
  round2,
} from '../utils'
import { useTranslation } from 'react-i18next'
import ChartHelpButtonComponent from 'common/ChartDetailHelp/ChartHelpButtonComponent'
import ChartHelpComponent from 'common/ChartDetailHelp/ChartHelpComponent'
import { useMunicipalityResultsPerTaskAverage } from './useMunicipalityAverage'
import ChildNonParticipation from './ChildNonParticipation'
import ChildNonParticipationPerAge from './ChildNonParticipationPerAge'


interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const findMaxAnswerCount = (data: any) => {
  let maxTotalAnswerCount = 0
  data.forEach((stat: any) => {
    if (stat.totalAnswerCount > maxTotalAnswerCount) {
      maxTotalAnswerCount = stat.totalAnswerCount
    }
  })

  return maxTotalAnswerCount
} 


interface ResultPerAgeChartProps extends Omit<StackProps, 'filter'> {
  title: string
  filter: DashboardFilterType
  helpText1: string
  helpText2: string
  helpDetailText1: string | undefined
  helpDetailText2: string | undefined
}

export const ResultsPerAnswerCount = ({
  title,
  filter,
  helpText1,
  helpText2,
  helpDetailText1,
  helpDetailText2,
  ...rest
}: ResultPerAgeChartProps) => {
  const { stats, loading } = useResultsPerTaskChart(filter)
  const {municipalityAverageData, error} = useMunicipalityResultsPerTaskAverage(filter)
 

  const { tasks } = useTaskService()
  const { t, i18n } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()

  const categories = (stats[0]?.scores || [])?.map((score, index) => ({
    key: `${score.taskId}_${score.categoryId}`,
    name: generateChartBarName({
      categoryId: score.categoryId,
      name: (tasks.find(task => task.id === score.taskId)?.name +
        ' (' +
        tasks.find(task => task.id === score.taskId)?.description +
        ') ' +
        '') as string,
    }),
    color: BarChartColors[index],
  }))

  const bars = categories.filter(bar => bar?.name)



 
  const data = stats.map(stat => {
    const { scores } = stat
    const stats = scores.reduce((acc, curr) => {
      const keyName = `${curr.taskId}_${curr.categoryId}`
      const totalAnswerCount = curr.answerCount + curr.noAnswerCount

      return {
        ...acc,
        [`${keyName}_answer_count`]: curr.answerCount,
        [`${keyName}_noAnswer_count`]: curr.noAnswerCount,
        // [`${keyName}_distracted_count`]: curr.distractedCount,
        // [`${keyName}_foreign_count`]: curr.foreignCount,
        // [`${keyName}_understand_count`]: curr.understandCount,
        totalAnswerCount,
      }
    }, {})

    return {
      ...stats,
      ageYear: `${stat.age} ${t('years')}`,
    }
  })




  const municipalityData = municipalityAverageData.map((stat:any) => {
    const { scores }:any = stat
    const stats = scores.reduce((acc, curr) => {
      const keyName = `${curr.taskId}_${curr.categoryId}`
      const totalAnswerCount = curr.answerCount + curr.noAnswerCount

      return {
        ...acc,
        [`${keyName}_answer_count`]: curr.answerCount,
        [`${keyName}_noAnswer_count`]: curr.noAnswerCount,
        totalAnswerCount,
      }
    }, {})

    return {
      ...stats,
      ageYear: `${stat.age} ${t('years')}`,
    }
  })



  const combinedData = data.map((item)=>{
    const municipalityItem = municipalityData.find(m => m.ageYear === item.ageYear)
    return{
      ...item,
      municipalityTotalAnswerCount : municipalityItem ? municipalityItem.totalAnswerCount : undefined

    }
  })



  const maxTotalAnswerCount = findMaxAnswerCount(data)



  // Define a buffer to extend the Y-axis
const buffer = 0.2 * maxTotalAnswerCount;  // 10% of maxTotalAnswerCount
const extendedMaxTotalAnswerCount = ceil(maxTotalAnswerCount + buffer);




  const [tooltip, setTooltip] = useState<string>('')
  const CustomTooltip = ({ active, payload, label }: any) => {

    
    if (active && payload && payload.length) {
      const answerCountDataKey = `${tooltip}_answer_count`;
      const noAnswerCountDataKey = `${tooltip}_noAnswer_count`;
    


  
      for (const bar of payload) {

   
     
  
      





        if ([
            answerCountDataKey,
            noAnswerCountDataKey,
            'totalAnswerCount',
          ].includes(bar.dataKey)
        ) {
          if (bar.dataKey !== 'totalAnswerCount') {
            return (
            
            
            <VStack
                bg='#344352'
                p='3'
                borderRadius='md'
                alignItems='flex-start'
                color='white'
              >
                <Text fontWeight='semibold'>{label}</Text>
                <HStack key={payload[0].name}>
                  <Text fontWeight='semibold'>{bar.name}</Text>
                </HStack>


            

                {
                  (filter.areaIds.length !== 0 || filter.departmentIds.length !== 0 || filter.preschoolIds.length !== 0) ?
                  <HStack key={`${tooltip}_total_answer_count_municipality`}>
                    <Text fontWeight='semibold'>{t('Total_Count_Municipality')} </Text>
                    <Text>{round2(payload[0].payload.municipalityTotalAnswerCount)}</Text>
                  </HStack>
                  :
                  <HStack key={`${tooltip}_total_answer_count_municipality`}>
                    <Text fontWeight='semibold'>{`${t('Total_Num_Children')}:`}</Text>
                    <Text>{round2(payload[0].payload.municipalityTotalAnswerCount)}</Text>
                  </HStack>
                }          
  
                {
                  (filter.areaIds.length !== 0 || filter.departmentIds.length !== 0 || filter.preschoolIds.length !== 0) &&
                  <HStack key={`${tooltip}_total_answer_count`}>
                    <Text fontWeight='semibold'>{t('Total_Responses')} </Text>
                    <Text>{round2(bar.payload.totalAnswerCount)}</Text>
                  </HStack>
                }
  
                <HStack key={`${answerCountDataKey}`}>
                  <Text fontWeight='semibold'>{t('Amount_Answers')} </Text>
                  <Text>{round2(bar.payload[answerCountDataKey])}</Text>
                </HStack>
  
                <HStack key={`${noAnswerCountDataKey}`}>
                  <Text fontWeight='semibold'>{t('Num_Not_Participate')} </Text>
                  <Text>{round2(bar.payload[noAnswerCountDataKey])}</Text>
                </HStack>

        
              </VStack>
            );
          } else {
            return (
              <VStack
                bg='#344352'
                p='3'
                borderRadius='md'
                alignItems='flex-start'
                color='white'
              >
                <Text fontWeight='semibold'>{label}</Text>
                <HStack key={payload[0].name}>
                  <Text fontWeight='semibold'>{bar.name}: </Text>
                  <Text>{round2(bar.value)}</Text>
                </HStack>
              </VStack>
            );
          }
        }
  
        // // Check if the bar.dataKey ends with specific strings
        // if (
        //   bar.dataKey.endsWith('_understand_count') ||
        //   bar.dataKey.endsWith('_distracted_count') ||
        //   bar.dataKey.endsWith('_foreign_count')
        // ) {
        //   return (
        //     <VStack
        //       bg='#344352'
        //       p='3'
        //       borderRadius='md'
        //       alignItems='flex-start'
        //       color='white'
        //     >
        //       <Text fontWeight='semibold'>{bar.name}</Text>
        //       <Text>{bar.value}</Text>
        //     </VStack>
        //   );
        // }
      }
  
      return null;
    }
    return null;
  };
  

// let showNotParticipateChart = false;


// console.log('combinedData',combinedData)
// // Filter logic
// const filteredItems = combinedData.filter(item => {
//   // Get all keys in the object that end with 'distracted_count', 'foreign_count', or 'understand_count'
//   const countKeys = Object.keys(item).filter(
//     key =>
//       key.endsWith('_distracted_count') ||
//       key.endsWith('_foreign_count') ||
//       key.endsWith('_understand_count')
//   );

//   // Check if none of the count values for this item are all zero
//   if(countKeys.every(key => item[key] === 0)){
//   showNotParticipateChart = false
//   }
//   else{
//     showNotParticipateChart = true
//   }
// });



let showNotParticipateChart = false;

// // Filter logic
// combinedData.forEach(item => {
//   // Get all keys in the object that end with 'distracted_count', 'foreign_count', or 'understand_count'
//   const countKeys = Object.keys(item).filter(
//     key =>
//       key.endsWith('_distracted_count') ||
//       key.endsWith('_foreign_count') ||
//       key.endsWith('_understand_count')
//   );

//   // Check if all the count values for this item are zero
//   const allZero = countKeys.every(key => item[key] === 0);

//   // If any item has non-zero values, set `showNotParticipateChart` to true
//   if (!allZero) {
//     showNotParticipateChart = true;
//   }
// });

let count = 0;
stats.forEach((item)=>{
 item.distractedCount === 0 && item.foreignCount === 0 && item.understandCount === 0 ? count = count + 1 : ''
})


if(count === stats.length){
  showNotParticipateChart = false
} else{
  showNotParticipateChart= true
}




const generateDynamicTicks = (maxValue, tickCount = 5) => {
  const interval:number = ceil(maxValue / (tickCount - 1)); // Calculate the interval based on the max value
  const ticks:number[] = [];
  for (let i:any = 0; i < tickCount; i++) {
    ticks.push(i * interval);
  }
  return ticks;
};

const dynamicTicks = generateDynamicTicks(extendedMaxTotalAnswerCount);


//   console.log('payload',payload)
 


//   if (active && payload && payload.length) {


//    for(let i=0; i <payload.length ; i=+3){

  
   
//     const understand = payload.find(item => item.dataKey.includes('_understand_count'));
//     const distracted = payload.find(item => item.dataKey.includes('_distracted_count'));
//     const foreign = payload.find(item => item.dataKey.includes('_foreign_count'));

//     return (
//       <div className="task-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
//         <p>{`Task: ${payload[4].name}`}</p>
//         {understand && <p>{`Child does not understand: ${understand.value}`}</p>}
//         {distracted && <p>{`Child distracted: ${distracted.value}`}</p>}
//         {foreign && <p>{`Child answered in foreign language: ${foreign.value}`}</p>}
//       </div>
//     );
//   }

//   }
// return null;

// };



  return (
    <ChartContainer
      title={title}
      helptext={helpText1}
      loading={loading}
      {...rest}
    >
      {data.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Text w={{ base: '100%', md: '80%' }} lineHeight={2}>
            {data.length > 0 && showNotParticipateChart ? helpText2 : helpText1}
          </Text>
          <ChartHelpButtonComponent onToggle={onToggle} isOpen={isOpen} />
        </Stack>
      )}

      {helpDetailText1 && (
        <ChartHelpComponent helpDetailText={showNotParticipateChart ? helpDetailText2: helpDetailText1} isOpen={isOpen} />
      )}
      <br></br>
      {data.length > 0 ? (
        <div style={{display:'flex', justifyContent: 'center', flexDirection:'row'}}>
        <ResponsiveContainer width={showNotParticipateChart ? '50%' : '100%'} height={520}>
          <ComposedChart
            data={combinedData}
            barGap='2%'
            margin={{ bottom: 55, top: 10 }}
          >
            <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
            <YAxis
             ticks={dynamicTicks}
             domain={[0, extendedMaxTotalAnswerCount]}
              fontSize={12}
              
            >
              <Label
                angle={-90}
                width={230}
                fontSize={'12px'}
                value={t('Num_Children')}
                textBreakAll={true}
                position='insideLeft'
                fontWeight='bold'
                style={{
                  textAnchor: 'middle',
                }}
                dx={5}
              />
            </YAxis>

          
            <XAxis dataKey='ageYear' fontSize={12}>
              <Label
                value={t('Overview_Task')}
                position='bottom'
                fontWeight='bold'
                fontSize={'12px'}
              />
            </XAxis>
            <Tooltip
              content={<CustomTooltip />}
              formatter={value => `${value}%`}
              cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
            />
            {bars.map((bar, index) => (
              <>
                <Bar
                  key={`${bar.key}_answer_count`}
                  dataKey={`${bar.key}_answer_count`}
                  name={bar.name}
                  fill={bar.color}
                  stackId={`${bar.key}_${index}`}
                  barSize={12}
                  isAnimationActive={true}
                  onMouseOver={() => {
                    setTooltip(`${bar.key}`)
                  }}
                />
                <Bar
                  key={`${bar.key}_noAnswer_count`}
                  dataKey={`${bar.key}_noAnswer_count`}
                  name={bar.name}
                  stackId={`${bar.key}_${index}`}
                  fill={'#D3D3D3'}
                  barSize={12}
                  legendType={'none'}
                  isAnimationActive={true}
                  onMouseOver={() => {
                    setTooltip(`${bar.key}`)
                  }}
                />
              </>
            ))}
            <Line
              legendType='square'
              dataKey={t('child_non_participation')}
              stroke='#d4d4d4'
            />
            <Line
              type='monotone'
              name={
                i18n.language === 'se'
                ? IndicatorLabels.AverageTotal
                : i18n.language === 'de'
                ? IndicatorLabelsDe.AverageTotal
                : IndicatorLabelsEn.AverageTotal
              }
              dataKey={'totalAnswerCount'}
              stroke={IndicatorColors.childrenResult}
              strokeWidth={3}
              onMouseOver={() => {
                setTooltip('totalAnswerCount')
              }}
            />
              {/* <Line
              type='monotone'
              name={
                i18n.language === 'en'
                  ? IndicatorLabelsEn.ChildrenResult
                  : IndicatorLabels.ChildrenResult
              }
              dataKey={'municipalityTotalAnswerCount'}
              stroke={IndicatorColors.childrenResult}
              strokeWidth={3}
              onMouseOver={() => {
                setTooltip('municipalityTotalAnswerCount')
              }}
            /> */}
            <Legend
              iconSize={10}
              formatter={LegendItemRenderer}
              verticalAlign='bottom'
              wrapperStyle={{ bottom: '15px' }}
            />
          </ComposedChart>
        </ResponsiveContainer>


{
  showNotParticipateChart && (
    <>
      {/* <ChildNonParticipation stats={stats}/> */}
<ChildNonParticipationPerAge stats={stats}/>
    </>
  
  )
}
       
       
        </div>
      ) : (
        <div className='nodata-avaliable'>
          {' '}
          {t('ResultsTable_No_Data')}
        </div>
      )}
    </ChartContainer>
  )
}
